import axios from "axios";

export const getAreas = async () => {
  return await axios
    .get(`${process.env.REACT_APP_HOST}/meta/Wbp6Gebied`)
    .then(({ data }) => data);
};

export const getFacets = async () => {
  return await axios
    .get(`${process.env.REACT_APP_HOST}/content/wbp6/facet/thema`)
    .then(({ data }) => {
      return data;
    });
};

export const getDispositionFacets = async (query = "", areas = []) => {
  return await axios
    .get(
      `${process.env.REACT_APP_HOST}/content/wbp6/facet/maatregel${
        areas.length > 0
          ? "?" + areas.map(({ id }) => "facet=" + id).join("&")
          : ""
      }${
        query.trim() !== ""
          ? (areas.length > 0 ? "&" : "?") + `text=${query}`
          : ""
      }`
    )
    .then(({ data }) => {
      return data;
    });
};

export const getDevelopments = async () => {
  return await axios
    .get(`${process.env.REACT_APP_HOST}/meta/Wbp6OntwikkelingLangeTermijn`)
    .then(({ data }) => data);
};

export const getThemes = async (
  filters = [],
  developments = [],
  cancelToken = null
) => {
  return await axios
    .get(
      `${process.env.REACT_APP_HOST}/content/wbp6/list/thema${
        filters.length === 0
          ? ""
          : "?" + filters.map(({ id }) => "facet=" + id).join("&")
      }${
        developments.length === 0
          ? ""
          : (filters.length === 0 ? "?" : "&") +
            developments.map(({ id }) => "facet=" + id).join("&")
      }`,
      { cancelToken }
    )
    .then(({ data }) => {
      return data;
    });
};

export const getTheme = async (id) => {
  return await axios
    .get(`${process.env.REACT_APP_HOST}/content/wbp6/${id}/thema`)
    .then(({ data }) => {
      return data;
    });
};

export const getDisposition = async (id) => {
  return await axios
    .get(`${process.env.REACT_APP_HOST}/content/wbp6/${id}/maatregel`)
    .then(({ data }) => data);
};

export const getDispositions = async (query = "", selectedAreas = []) => {
    const trimmedQuery = query.trim();

    try {
        const [maatregelData, themeData] = await Promise.all([
            axios.get(`${process.env.REACT_APP_HOST}/content/wbp6/list/maatregel`),
            axios.get(`${process.env.REACT_APP_HOST}/content/wbp6/list/thema`)
        ]);

        if (maatregelData.status === 404 || themeData.status === 404) {
            throw new Error("Resource not found: 404 error");
        }

        const maatregelItems = maatregelData.data;
        const themeItems = themeData.data;

        const updatedDispositions = [];

        for (const theme of themeItems) {
            for (const themeDisposition of theme.maatregelen) {
                for (const disposition of maatregelItems) {
                    if (themeDisposition.maatregel.id === disposition.id) {
                        updatedDispositions.push({
                            ...themeDisposition.maatregel,
                            disposition,
                            themeTitle: theme.title
                        });
                    }
                }
            }
        }

        const filteredDispositions = updatedDispositions.filter((disposition) => {
            const isMatchingQuery = (
                disposition.disposition.title.toLowerCase().includes(trimmedQuery.toLowerCase()) ||
                disposition.themeTitle.toLowerCase().includes(trimmedQuery.toLowerCase()) ||
                disposition.disposition.inhoud.toelichting.toLowerCase().includes(trimmedQuery.toLowerCase())
            );

            const isMatchingArea = selectedAreas.length === 0 || selectedAreas.some((selectedArea) => {
                return disposition.disposition.inhoud.gebied.includes(selectedArea.value);
            });

            return isMatchingQuery && isMatchingArea;
        });

        return filteredDispositions;
    } catch (error) {
        console.error("Error in getDispositions:", error.message);
        throw error;
    }
};